<template>
  <div class="row">

    <div class="col-xs-12 col-sm-6 col-lg-4 mb-2">
      <div class="card card-body card-status">
        <div class="row row-sm">
          <font-awesome-icon v-b-popover.hover="`El porcentaje de cumplimiento está en función del total de documentos que se han cargado hasta el mes ${periodo} y los meses previos.`"
          class="icon" :icon="{ prefix: 'fa', iconName: 'exclamation-circle', }" style="font-size: 15px; color: rgb(35, 35, 47); position: absolute; float: right; right: 10px; top: 5px; display: inline-block; opacity: 1;"/>
          <div style="display: flex;column-gap: 10px;">
            <div class="media">
              <peity :type="'pie'" :options="{ 'fill': ['#168736', '#eeeeee'], width: 45, height:45 }" :data="`${porcentajeEnvio ? porcentajeEnvio.toString() :  0}/100`"></peity>
            </div>
            <div>
              <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Cumplimiento de envío de archivos</h6>
              <div class="d-flex d-lg-block d-xl-flex align-items-end">
                <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
                  <span>{{ `${porcentajeEnvio || ''}%`}}</span>
                </h3>
                <p class="tx-11 tx-color-03 mg-b-0">Envío de archivos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-lg-4 mb-2">
      <div class="card card-body card-status">
        <div class="row row-sm">
          <font-awesome-icon v-b-popover.hover="`El porcentaje de cumplimiento está en función de los documentos sin marca de riesgo hasta el mes ${periodo} y los meses previos.`"
          class="icon" :icon="{ prefix: 'fa', iconName: 'exclamation-circle', }" style="font-size: 15px; color: rgb(35, 35, 47); position: absolute; float: right; right: 10px; top: 5px; display: inline-block; opacity: 1;"/>
          <div style="display: flex;column-gap: 10px;">
            <div class="media">
              <peity :type="'pie'" :options="{ 'fill': ['#168736', '#eeeeee'], width: 45, height:45 }" :data="`${porcentajeValido ? porcentajeValido.toString() : 0}/100`"></peity>
            </div>
            <div>
              <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Cumplimiento de documentos válidos</h6>
              <div class="d-flex d-lg-block d-xl-flex align-items-end">
                <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
                  <span>{{ `${porcentajeValido || ''}%` }}</span>
                </h3>
                <p class="tx-11 tx-color-03 mg-b-0">Documentos válidos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-lg-4 mb-2">
      <div class="card card-body card-status">
        <div class="row row-sm">
          <font-awesome-icon v-b-popover.hover.html="`Todas las noches se válida de forma automática qué proveedores estan en las listas negras del SAT.<br>${sentencias}`"
          class="icon" :icon="{ prefix: 'fa', iconName: 'exclamation-circle', }" style="font-size: 15px; color: rgb(35, 35, 47); position: absolute; float: right; right: 10px; top: 5px; display: inline-block; opacity: 1;"/>
          <div style="display: flex;column-gap: 10px;">
            <div class="media">
              <font-awesome-icon v-if="listaNegra.length" class="icon" :icon="{ prefix: 'fa', iconName: 'times-circle', }" style="font-size: 40px; color: rgb(219 34 34); display: inline-block; opacity: 1;"></font-awesome-icon>
              <font-awesome-icon v-else class="icon" :icon="{ prefix: 'fa', iconName: 'check-circle', }" style="font-size: 40px; color: rgb(22, 135, 54); display: inline-block; opacity: 1;"></font-awesome-icon>
            </div>
            <div>
              <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Listas negras</h6>
              <div class="d-flex d-lg-block d-xl-flex align-items-end">
                <p class="tx-11 tx-color-03 mg-b-0">El proveedor <span v-if="!listaNegra.length">NO</span> está en lista negra</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Peity from 'vue-peity';

export default {
  name: 'EstatusCards',
  components: {
    Peity,
  },
  props: [
    'periodo',
    'porcentajeEnvio',
    'porcentajeValido',
    'listaNegra',
  ],
  computed: {
    sentencias() {
      if (!this.listaNegra.length) {
        return '';
      }
      return this.listaNegra.map((registro) => {
        // eslint-disable-next-line no-nested-ternary
        const situacion = registro.Situacion === 'DF' ? 'Definitivo' : (registro.Situacion === 'DV' ? 'Desvirtuado' : (registro.Situacion === 'P' ? 'Presunto' : (registro.Situacion === 'SF' ? 'Sentencia Favorable' : '')));
        return `<strong>Fecha:</strong> ${registro.Fecha} <strong>Situación:</strong> ${situacion}<br>`;
      });
    },
  },
};
</script>
