<template>
  <div>

    <h5>Periodo del Proyecto</h5>

    <table class="table table-info table-light">
      <tbody>
        <tr>
          <td class="table-dark">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'calendar-alt', }" class="icon btn-icon"/> Fecha Inicial del Proyecto
          </td>
          <td>{{ datos.FechaIniProy }}
            <a class="link" @click="msgBoxEditFechas" v-if="$editar(['Proveedores'])" v-b-popover.hover.bottom="'Editar fechas del Proyecto'">
              <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'edit', }" class="icon btn-icon" />
            </a>
        </td>
        </tr>
        <tr>
          <td class="table-dark">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'calendar-alt', }" class="icon btn-icon"/> Fecha Final del Proyecto
          </td>
          <td>{{ datos.FechaFinProy }}
            <a class="link" @click="msgBoxEditFechas" v-if="$editar(['Proveedores'])" v-b-popover.hover.bottom="'Editar fechas del Proyecto'">
              <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'edit', }" class="icon btn-icon" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <b-modal
      id="bv-modal-periodo-proy" size="sm" centered hide-footer content-class="site-modal"
      :header-text-variant="'light'"
      v-if="$editar(['Proveedores'])">
      <template #modal-title>
        Periodo del Proyecto
      </template>
      <form class="form-site inner inner-sm" @submit.prevent="actualizarDias()">
        <div class="form-row">
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('fechaIniProy') ? 'select-error' : ''">
              <legend>Fecha Inicial del Proyecto</legend>
              <date-picker
                type="date"
                :placeholder="'DD-MM-AAAA'"
                :clearable="false"
                :format="'DD-MM-YYYY'"
                :formatter="formatedFecha"
                v-model="fechaIniProy">
                <template v-slot:header="{ emit }">
                  <button class="mx-btn mx-btn-text" @click="emit(new Date())">* HOY</button>
                </template>
              </date-picker>
            </fieldset>
          </div>
        </div>
        <div class="form-row">
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('fechaFinProy') ? 'select-error' : ''">
              <legend>Fecha Final del Proyecto</legend>
              <date-picker
                type="date"
                :placeholder="'DD-MM-AAAA'"
                :clearable="false"
                :format="'DD-MM-YYYY'"
                :formatter="formatedFecha"
                v-model="fechaFinProy">
                <template v-slot:header="{ emit }">
                  <button class="mx-btn mx-btn-text" @click="emit(new Date())">* HOY</button>
                </template>
              </date-picker>
            </fieldset>
          </div>
        </div>
        <button type="submit" class="btn btn-block btn-action mb-2" block>
          <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
          <font-awesome-icon v-if="!loading" :icon="{ prefix: 'fa', iconName: 'save', }" class="icon btn-icon" />Guardar
        </button>
      </form>
    </b-modal>

  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  name: 'DiasCarga',
  props: [
    'datos',
    'provId',
  ],
  data() {
    return {
      loading: false,
      fechaIniProy: null,
      fechaFinProy: null,
      formatedFecha: {
        stringify: (date) => this.$formatDateMX(date) || '',
      },
    };
  },
  validators: {
    fechaIniProy(value) { return Validator.value(value).required('Campo requerido'); },
    fechaFinProy(value) { return Validator.value(value).required('Campo requerido'); },
  },
  methods: {
    msgBoxEditFechas() {
      this.$bvModal.msgBoxConfirm('Si continua, se actualizarán las fechas de duracion del proyecto solamente de esta Orden de Compra.', {
        title: 'Editar Fechas',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si Continuar',
        cancelTitle: 'Cancelar',
        headerClass: 'text-light',
        footerClass: 'bg-light p-2',
        hideHeaderClose: true,
        centered: true,
      })
        .then((confirm) => {
          if (confirm) this.openEditFechas();
        })
        .catch(() => {});
    },
    openEditFechas() {
      this.fechaIniProy = this.$mxToDate(this.datos.FechaIniProy);
      this.fechaFinProy = this.$mxToDate(this.datos.FechaFinProy);
      this.$bvModal.show('bv-modal-periodo-proy');
    },
    actualizarDias() {
      this.$validate().then((success) => {
        if (success) {
          this.loading = true;
          apiClient.put(`/orden/${this.datos.OrdenId}/periodo-proyecto`, {
            ProvId: this.provId,
            fechaIniProy: this.$formatDate(this.fechaIniProy),
            fechaFinProy: this.$formatDate(this.fechaFinProy),
          })
            .then(() => {
              this.$emit('submit');
              this.$bvModal.hide('bv-modal-periodo-proy');
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
